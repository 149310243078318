import { isDefined } from "@components/config/helpers";
import actions from "@components/Redux/Category/actions";
import globalActions from "@components/Redux/Global/actions";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useGeneralActions from "../useGeneralActions";
import useSafePush from "../useSafePush";


const useCategoryShallowLink = () => {

    const router = useRouter();
    const dispatch = useDispatch();
    // const {setPageSpinner} = useGeneralActions();

    const template_settings = useSelector(state => state.globalReducer.template_settings);
    const categoryState = useSelector(state => state.categoryReducer.categoryState);
    const getProducts = async (route) => {
        const req = await fetch(`${process.env.BASE_API_URL}get-category?slug=${route}`); //find the category data from the url
        const data = await req.json();
        return data;
    }

    // console.log(categoryState)

    const {safePush} = useSafePush();

    const handleShallowLink = async (e, route, staticPage = false) => {
        
        // TODO: article because of this
        if(categoryState?.category?.length != 0 && !staticPage){
            safePush(`/${route}`, undefined, true);
            e?.preventDefault(); 
            // Trigger data fetching for the next page
            dispatch({
                type: actions.SET_LOADER,
                payload: {key: 'fetchingCategory', value: true}
            })
            const products = await getProducts(route)
            const data = {...products, newRoute: route}
            dispatch({
                type: actions.SET_CATEGORY,
                payload: data
            })

            dispatch({
                type: actions.SET_LOADER,
                payload: {key: 'fetchingCategory', value: false}
            })
            dispatch({
                type: globalActions.SET_GLOBAL_DATA,
                payload: {
                    breadcrumbs: products.breadcrumb,
                    sidenav: products?.template_page_settings?.sidenav == 'yes' && template_settings.sidenav == 'yes'
                }
            })
        }
        else {
            safePush('/' + route);
        }
    };
      


    return {
        handleShallowLink
    }
}

export default useCategoryShallowLink;