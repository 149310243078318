import Box  from '@mui/material/Box'
import React, { useState,Fragment, useMemo } from 'react'
import dynamic from 'next/dynamic';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { count } from '../../../config/helpers';
import Link from 'next/link';
import useShallowLink from '@components/_hooks/CSRHooks/useCategoryShallowLink';
import useGlobalSettings from '@components/_hooks/useGlobalSettings';
const HeaderCategoryBox= dynamic(() => import("./HeaderCategoryBox"));
const BoxContainer= dynamic(() => import("@components/Components/Reusables/BoxContainerV2"));
//new 
const HeaderCategories = () => {

    const {handleShallowLink} = useShallowLink();
    const {getSetting} = useGlobalSettings();

  const [categoryOpen, setCategoryOpen] = useState({
    index: null,
    open: true
  });

  const [clicked, setClicked] = useState(null);

  const handleOpen = (index) => {
    if(clicked == null){
        setCategoryOpen({
            index: index,
            open: true,
        });
    }
  };
  const handleClose = () => {
    if(clicked != null){
        setClicked(null);
    }
    setCategoryOpen({
        index: categoryOpen.index,
        open: false,
    });
  };


  const prettified_categories = useSelector(state => state.globalReducer.prettified_categories);
  const template_settings = useSelector(state => state.globalReducer.template_settings);
  
  const filteredCategories = useMemo(() => {
    return prettified_categories.filter((category, index) => category.parent_id === 0 && category.visible === 'yes' && (index < template_settings.no_of_home_categories || category?.static && category?.ignoreHidden == true));
  }, [prettified_categories]); // Dependencies array

  const checkCategorySonsVisbility = (index) => {
    const cat = filteredCategories[index];

    if(cat.sons && count(cat.sons)){
        const vis = cat.sons.filter(sub_cat => sub_cat.visible == 'yes' && sub_cat.status == 'enabled');
        if(typeof vis != 'undefined' && !isNaN(vis.length) && count(vis) > 0){
            return true;
        }
    }

    return false;
  }

  const navigated = (e, slug, staticPage) => {
    e.preventDefault();
    setClicked(null);
    handleClose(0)
    handleShallowLink(e, slug, staticPage)
  }


  return (
    <>
        <Box 
            // onMouseOver={ (e) => mouseEvent(e, null)}
            onMouseLeave={() => handleClose(0)}
            sx={{
                width: '100%',
                justifyContent: 'center',
                paddingBottom: '0.25rem',
                color:'primary', 
                backgroundColor: 'white.main',
                zIndex:200,
            }}
        >
            <Box className="w-100 box-shadow">
                <BoxContainer>
                    <List
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingBottom: '0.25rem',
                            minHeight: '31px'
                        }}
                    >
                        {filteredCategories?.map((category, index) => (
                            <Fragment key={index}>
                                <Link  
                                    scroll={false}
                                    href={`/${category.slug}`}
                                    onClick={(e) => navigated(e, category.slug, category?.static)}
                                >
                                    <ListItem
                                        onMouseEnter={(e) => handleOpen(index)} 
                                        className="hover-underline"
                                        sx={{
                                            p: 0,
                                            ':hover': {
                                                background: (theme)=> theme.palette.grey[200]
                                            },
                                            width: 'fit-content'
                                        }}
                                    >
                                    
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    sx={{
                                                        paddingLeft: '4px',
                                                        paddingRight: '4px',
                                                        fontWeight: 500,
                                                        color: getSetting('header_category_colour', 'primary.main'),
                                                        fontSize:{ sm: 12, md:12, lg:getSetting('header_font_size', '14px'), xl:getSetting('header_font_size', '14px')}
                                                        
                                                    }}
                                                >
                                                    {category.name}
                                                </Typography>
                                            }    
                                        >

                                        </ListItemText>
                                    </ListItem>
                                </Link>
                            </Fragment>
                        ))}
                    </List>
                </BoxContainer>
            </Box>
            
            {categoryOpen?.index != null && filteredCategories.map((category, index) => (
                checkCategorySonsVisbility(index) && (
                    <Box
                        key={index}
                        sx={{
                            position: 'absolute',
                            zIndex: 2,
                            width: '100%',
                            bgcolor: '#eee'
                        }}
                    >
                        <Collapse  
                            onMouseLeave={() => handleClose(index)}
                            className="boxed-padding" 
                            in={categoryOpen.open && categoryOpen?.index == index}
                        >
                            <HeaderCategoryBox 
                                cat_open={categoryOpen.open} 
                                cat_index={categoryOpen.index}
                                navigated={navigated}
                                index={index}
                            />
                        </Collapse>
                    </Box>
                )
            ))}
        </Box>
    </>
  )
}

export default React.memo(HeaderCategories);